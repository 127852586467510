const convertBase64ToBlob = (bytes, contentType) => {
  let raw = atob(bytes)
  let uintArray = new Uint8Array(new ArrayBuffer(raw.length))
  for (var i = 0; i < raw.length; i++) {
    uintArray[i] = raw.charCodeAt(i)
  }
  return new Blob([uintArray], { type: contentType })
}
export const downloadFile = response => {
  try {
    const blob = convertBase64ToBlob(
      response.fileContents,
      response.contentType
    )

    const filename = response.fileDownloadName

    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename)
      return
    }

    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('target', '_blank')
    link.setAttribute('type', 'hidden')
    link.setAttribute('download', filename)
    link.href = url
    document.body.appendChild(link)
    link.click()
  } catch (error) {
    console.log(error)
  }
}
