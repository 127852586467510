import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateSessionExpiryTimestamp } from '../actions/auth.actions'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
  Button,
} from '@material-ui/core'
import { signOutRedirect } from '../services/auth.service'
import { useLocation } from 'react-router-dom'

const InactivityMonitor = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const [secondsRemaining, setSecondsRemaining] = useState(null)
  const { sessionExpiryTime, isAuthenticated } = useSelector(
    state => state.auth
  )

  // calculate time remaining and show modal/logout user
  useEffect(() => {
    let timer

    if (secondsRemaining && secondsRemaining <= 0) {
      signOutRedirect()
    }

    if (isAuthenticated) {
      timer = setInterval(() => {
        const currentTime = new Date().getTime()
        const difference = sessionExpiryTime - currentTime
        const seconds = Math.floor(difference / 1000)
        setSecondsRemaining(seconds)
      }, 1000)
    }

    return () => {
      clearInterval(timer)
    }
  }, [secondsRemaining, isAuthenticated, sessionExpiryTime])

  // update inactivity timeout every time user changes route
  useEffect(() => {
    dispatch(updateSessionExpiryTimestamp())
  }, [dispatch, location])

  return (
    <>
      <Dialog open={secondsRemaining !== null && secondsRemaining <= 60}>
        <DialogTitle>Are you still there?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Your session will expire in '}
            <strong>{secondsRemaining > 0 ? secondsRemaining : 0}</strong>
            {' seconds'}
            <br />
            {'When your session expires you will be logged out.'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => dispatch(updateSessionExpiryTimestamp())}
            color='secondary'>
            Keep working
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InactivityMonitor
