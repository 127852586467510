import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  loadingSpinner: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
}))

const LoadingSpinner = ({ text }) => {
  const classes = useStyles()
  return (
    <div className={classes.loadingSpinner}>
      <CircularProgress color='primary' />
      {text && <p data-testid='loadingSpinnerText'>{text}</p>}
    </div>
  )
}

export default LoadingSpinner
