import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import LoadingPage from '../components/loadingPage'
import { fetchSectionTopicData } from '../actions/topics.actions'
import ThemeLoader from '../components/branding/themeLoader'
import Header from '../components/header'
import SubHeader from '../components/subheader'
// import Footer from '../components/footer'
import SideDrawer from '../components/sideDrawer'
import { Container } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import { getServiceLabelAsync } from '../actions/serviceLabel.actions'
import AccessLevelBanner from '../components/accessLevel/accessLevelBanner'

const MainLayout = ({ children }) => {
  const dispatch = useDispatch()

  const { sectionTopics } = useSelector(state => state.section)
  const { serviceLabelData } = useSelector(state => state.serviceLabel)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!sectionTopics) {
      setIsLoading(true)
      dispatch(fetchSectionTopicData())
    }

    if (!serviceLabelData) {
      setIsLoading(true)
      dispatch(getServiceLabelAsync())
    }

    if (sectionTopics && serviceLabelData) {
      setIsLoading(false)
    }
  }, [dispatch, sectionTopics, serviceLabelData])

  const [open, setOpen] = useState(false)

  return (
    <ThemeLoader>
      {isLoading ? (
        <LoadingPage text={'Loading...'} />
      ) : (
        <>
          <CssBaseline />
          <Header />
          <SubHeader open={open} setOpen={setOpen} />
          <SideDrawer open={open} setOpen={setOpen} />
          <main>
            <Container>{children}</Container>
          </main>
          <AccessLevelBanner />
        </>
      )}
    </ThemeLoader>
  )
}

export default MainLayout
