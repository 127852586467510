import React, { useEffect } from 'react'
import { signInRedirectCallback } from '../services/auth.service'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    margin: '32px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

function SignInCallback() {
  const classes = useStyles()
  const history = useHistory()

  useEffect(() => {
    signInRedirectCallback().then(user => {
      window.history.replaceState(
        {},
        window.document.title,
        window.location.origin + window.location.pathname
      )

      history.push((user.state && user.state.postLoginRedirect) || '/')
    })
  }, [history])

  return (
    <div className={classes.container}>
      <CircularProgress />
      <p>Logging in</p>
    </div>
  )
}

export default SignInCallback
