import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Drawer from '@material-ui/core/Drawer'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  hideAccessLevelPrompt,
  updateAccessLevel,
} from '../../actions/appconfig.actions'
import {
  Grid,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { signInSilent } from '../../services/auth.service'
import { useHistory } from 'react-router'
import { fetchSectionTopicData } from '../../actions/topics.actions'
import { enqueueSnackbar } from '../../actions/notification.actions'
import { getVisibleAccessLevels } from '../../services/accessLevel.service'
import { getServiceBranding } from '../../actions/branding.actions'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(4),
    marginBottom: 40,
    marginTop: 40,
    textAlign: 'left',
    color: theme.palette.getContrastText(theme.palette.header.main),
    background: 'none',
  },
  grid: {
    backgroundColor: theme.palette.header.main,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}))

const AccessLevelBanner = () => {
  const { serviceLabelData } = useSelector(state => state.serviceLabel)
  const history = useHistory()
  const [selectedAccessLevelId, setSelectedAccessLevelId] = useState(null)
  const classes = useStyles()
  const dispatch = useDispatch()
  const [showAccessLevelPicker, setShowAccessLevelPicker] = useState(false)
  const [isLoadingNewToken, setIsLoadingNewToken] = React.useState(false)
  const { accessLevelPromptHidden } = useSelector(state => state.appConfig)
  const [isLoadingAccessLevels, setIsLoadingAccessLevels] = useState(true)
  const [shownAccessLevels, setShownAccessLevels] = useState([])

  const handleClose = async accessLevelId => {
    if (!isLoadingNewToken) {
      setIsLoadingNewToken(true)
      try {
        if (getActiveAccessLevel().id !== JSON.parse(accessLevelId)) {
          await dispatch(updateAccessLevel(accessLevelId))
          await signInSilent()
          await getServiceBranding()
          await dispatch(fetchSectionTopicData())

          window.location.reload()
        }
      } catch (error) {
        console.error(error)
        dispatch(
          enqueueSnackbar({
            message: `Could not change access levels`,
            options: {
              variant: 'error',
            },
          })
        )
      }
      dispatch(hideAccessLevelPrompt())
    }
  }
  const accessLevels = useSelector(state =>
    state.auth?.user?.profile?.access_levels
      ? JSON.parse(state.auth.user.profile.access_levels)
      : []
  )

  const getActiveAccessLevel = () => {
    var activeLevel = accessLevels.find(al => al.active)
    if (!activeLevel) {
      activeLevel = accessLevels[0]
      activeLevel.active = true
    }
    return activeLevel
  }
  const updateSelectedId = e => {
    setSelectedAccessLevelId(e.target.value)
  }

  useEffect(() => {
    if (accessLevels.length > 1 && !accessLevelPromptHidden)
      setShowAccessLevelPicker(true)
  }, [accessLevelPromptHidden, accessLevels])

  useEffect(() => {
    if (!accessLevelPromptHidden) setIsLoadingNewToken(false)
  }, [accessLevelPromptHidden])
  // set flag when we have valid access levels array
  useEffect(() => {
    if (accessLevels.length && isLoadingAccessLevels) {
      setSelectedAccessLevelId(getActiveAccessLevel().id.toString())
      getVisibleAccessLevels().then(response => {
        // setShownAccessLevels(response.data)
        setShownAccessLevels(accessLevels.filter(function (level) {
          return response.data.includes(level.id)
        }))
        setIsLoadingAccessLevels(false)
      })
    }
  }, [isLoadingAccessLevels])

  return (
    <>
      {showAccessLevelPicker && (
        <Drawer
          anchor={'bottom'}
          className={classes.drawer}
          open={!accessLevelPromptHidden}
          onClose={() => handleClose(selectedAccessLevelId)}
          ModalProps={{
            disableBackdropClick: true,
            disableEscapeKeyDown: true,
          }}>
          <Grid container className={classes.grid}>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <Paper elevation={0} className={classes.paper}>
                <h3>Change {serviceLabelData.accessLevelsHeading}</h3>
                <div style={{ marginBottom: 10 }}>
                  You are currently viewing content from the{' '}
                  {getActiveAccessLevel().title}{' '}
                  {serviceLabelData.accessLevelsHeading}.
                </div>
                <div>
                  Please select to view content from a different{' '}
                  {serviceLabelData.accessLevelsHeading}.
                </div>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper elevation={0} className={classes.paper}> 
              {/* TODO: HERE */}
              {isLoadingAccessLevels ? (
                <CircularProgress/>
              ) : (
                <RadioGroup
                  onChange={updateSelectedId}
                  value={selectedAccessLevelId}>
                  {shownAccessLevels.length &&
                      shownAccessLevels.map(level => ( 
                      <FormControlLabel
                        key={level.id.toString()}
                        value={level.id.toString()}
                        control={<Radio color='primary' />}
                        label={level.title}></FormControlLabel>
                    ))}
                </RadioGroup>
              )}
                <br />
                <div className={classes.root}>
                  <div className={classes.wrapper}>
                    <Button
                      className={classes.button}
                      variant='contained'
                      onClick={() => handleClose(selectedAccessLevelId)}
                      color='secondary'
                      disabled={isLoadingNewToken || isLoadingAccessLevels}>
                      Continue
                    </Button>
                    {isLoadingNewToken && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </div>
              </Paper>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Drawer>
      )}
    </>
  )
}

export default AccessLevelBanner
