import {
  HIDE_ACCESS_LEVEL_PROMPT,
  SHOW_ACCESS_LEVEL_PROMPT,
} from '../actions/types'

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  accessLevelPromptHidden: true,
}

export default createReducer(initialState, {
  [HIDE_ACCESS_LEVEL_PROMPT]: state => {
    state.accessLevelPromptHidden = true
  },
  [SHOW_ACCESS_LEVEL_PROMPT]: state => {
    state.accessLevelPromptHidden = false
  },
})
