import {
  HIDE_ACCESS_LEVEL_PROMPT,
  SHOW_ACCESS_LEVEL_PROMPT,
  GET_ACCESS_LEVEL_REQUEST,
  GET_ACCESS_LEVEL_SUCCESS,
  GET_ACCESS_LEVEL_FAILURE,
} from './types'
import appConfigService from '../services/appConfig.service'

export function hideAccessLevelPrompt() {
  return {
    type: HIDE_ACCESS_LEVEL_PROMPT,
  }
}

export function showAccessLevelPrompt() {
  return {
    type: SHOW_ACCESS_LEVEL_PROMPT,
  }
}

export function updateAccessLevel(accessLevelId) {
  return async dispatch => {
    dispatch(serviceAccessLevelRequest(accessLevelId))
    try {
      const accessLevels = await appConfigService.updateAccessLevel(
        accessLevelId
      )
      dispatch(serviceAccessLevelSuccess(accessLevels ?? null))
    } catch (error) {
      dispatch(serviceAccessLevelFailure())
    }
  }
}

function serviceAccessLevelRequest() {
  return {
    type: GET_ACCESS_LEVEL_REQUEST,
  }
}

function serviceAccessLevelSuccess(response) {
  return {
    type: GET_ACCESS_LEVEL_SUCCESS,
    payload: response,
  }
}

function serviceAccessLevelFailure() {
  return {
    type: GET_ACCESS_LEVEL_FAILURE,
  }
}
