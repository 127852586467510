import {
  STORE_USER,
  STORE_USER_ERROR,
  USER_EXPIRING,
  USER_EXPIRED,
  UPDATE_SESSION_EXPIRY_TIMESTAMP,
  SERVICE_ACCESS_SUCCESS,
  SERVICE_ACCESS_REQUEST,
  SERVICE_ACCESS_FAILURE,
} from '../actions/types'
import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  isAuthenticated: false,
  serviceHasAccess: null,
  isCheckingServiceAccess: false,
  isExpiring: false,
  sessionExpiryTime: new Date().getTime() + 30 * 60000,
  profile: {},
}

export default createReducer(initialState, {
  [STORE_USER]: (state, action) => {
    state.isAuthenticated = true
    state.user = action.payload
    state.profile = action.payload.profile
    state.isExpiring = false
  },
  [STORE_USER_ERROR]: (state, action) => {
    state.isAuthenticated = false
  },
  [USER_EXPIRING]: state => {
    state.isExpiring = true
  },
  [USER_EXPIRED]: state => {
    state.isAuthenticated = false
    state.isExpiring = false
  },
  [UPDATE_SESSION_EXPIRY_TIMESTAMP]: (state, action) => {
    state.sessionExpiryTime = action.payload
  },
  [SERVICE_ACCESS_REQUEST]: (state, action) => {
    state.isCheckingServiceAccess = true
  },
  [SERVICE_ACCESS_SUCCESS]: (state, action) => {
    state.isCheckingServiceAccess = false
    state.serviceHasAccess = action.payload
  },
  [SERVICE_ACCESS_FAILURE]: (state, action) => {
    state.isCheckingServiceAccess = false
    state.serviceHasAccess = false
    state.isAuthenticated = false
  },
})
