import httpClient from '../utils/axios'
import {
  handleServiceError,
  handleServiceResponse,
} from '../utils/serviceResponseHelper'

export function fetchContentDocument(queryString) {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/DownloadDocument?${queryString}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

export function fetchContentDocumentByLegacyId(legacyDocumentId) {
  return httpClient
    .get(
      `${window.config.KNOWLEDGEPORTAL_API_URL}/v1/ContentManagement/DownloadDocument/${legacyDocumentId}`
    )
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}
