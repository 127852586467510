import React, { useEffect, useState } from 'react'
import { Avatar } from '@material-ui/core'
import GetIcon from '../components/documentIcons'
import { makeStyles } from '@material-ui/core/styles'
import { fetchContentDocument } from '../services/download.service'
import { downloadFile } from '../utils/fileUtils'
import { enqueueSnackbar } from '../actions/notification.actions'
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  small: {
    width: theme.spacing(2),
    height: theme.spacing(2),
  },
  spanLink: {
    color: '#2980b9',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const DocumentLink = ({
  documentId,
  fileName,
  documentName,
  showIcon,
  sectionAndTopicDetails,
  documentLocation,
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const createDocumentQueryString = () => {
    const queryObject = {
      sectionId: sectionAndTopicDetails.sectionId,
      sectionName: sectionAndTopicDetails.sectionName,
      topicId: sectionAndTopicDetails.topicId,
      topicName: sectionAndTopicDetails.topicName,
      documentId,
      fileName,
      documentName,
      documentLocation,
    }
    return Object.keys(queryObject)
      .map(key => key + '=' + queryObject[key])
      .join('&')
  }

  const handleClick = async () => {
    try {
      const documentData = await fetchContentDocument(
        createDocumentQueryString()
      )
      if (documentData?.fileContents) {
        downloadFile(documentData)
      } else {
        handleDownloadError(document)
      }
    } catch (error) {
      handleDownloadError(document)
    }
  }

  const handleDownloadError = () => {
    console.error(
      `Could not download documentID: ${documentId}, Filename: ${documentName}`
    )
    dispatch(
      enqueueSnackbar({
        message: `Could not download document.`,
        options: {
          variant: 'error',
        },
      })
    )
  }

  return (
    <span className={classes.spanLink} onClick={handleClick}>
      {showIcon ? (
        <span style={{ display: 'flex' }}>
          <Avatar
            variant='square'
            src={GetIcon(
              `.${fileName.split('.')[fileName.split('.').length - 1]}`
            )}
            className={classes.small}
          />
          <span style={{ marginLeft: 10 }}>{documentName}</span>
        </span>
      ) : (
        <span>{documentName}</span>
      )}
    </span>
  )
}

export default DocumentLink
