import { serviceConstants } from '../constants/service.constants'
import { serviceService } from '../services/service.service'

export const serviceActions = {
  get,
}

function get(serviceId) {
  return dispatch => {
    dispatch(request())

    serviceService.get(serviceId).then(
      response => {
        return dispatch(success(response, serviceId))
      },
      error => {
        dispatch(failure(error))
      }
    )
  }

  function request() {
    return { type: serviceConstants.GET_REQUEST }
  }
  function success(response, serviceId) {
    return { type: serviceConstants.GET_SUCCESS, payload: response }
  }
  function failure(error) {
    return { type: serviceConstants.GET_FAILURE, error }
  }
}
