import React, { useEffect } from 'react'
import './GnattaChat.css'
import logo from './Gnatta.svg'
import PropTypes from 'prop-types'

const GnattaChat = ({ UserId, Name, WebChatClientCode }) => {
  // These GUID are found on Gnatta website
  const data = [
    [UserId, 'c39a9be3-8b6e-47e1-9149-81400ffadf62'],
    [Name, 'c674a3cd-e319-4fe9-b37c-12dd24e5635b'],
  ]
  /* eslint-disable no-undef */
  useEffect(() => {
    data.forEach(value => {
      gnatta.set(value[1], {
        value: value[0],
      })
    })

    gnatta.start(
      'f69af66f-673c-49e2-b7c9-d6ff9e50dfcb',
      'https://chat.system.gnatta.com'
    )

    gnatta.register(
      WebChatClientCode,
      'gwc-chatAvailable',
      'gwc-chatBusy',
      'gwc-chatOffline'
    )
  }, [])
  /* eslint-enable no-undef */

  return (
    <div className='gnattaChat' id='gnatta-chat'>
      <button type='button' className='hidden' id='gwc-chatAvailable'>
        <img src={logo} alt='Logo' />
        <div className='availabilityBadge'></div>
      </button>
      <button type='button' className='hidden' id='gwc-chatBusy'>
        <img src={logo} alt='Logo' />
        <div className='availabilityBadge'></div>
      </button>
      <button type='button' className='hidden' id='gwc-chatOffline'>
        <img src={logo} alt='Logo' />
        <div className='availabilityBadge'></div>
      </button>
    </div>
  )
}

GnattaChat.propTypes = {
  EmployeeId: PropTypes.string.isRequired,
  Name: PropTypes.string.isRequired,
  WebChatClientCode: PropTypes.string.isRequired,
}

export default GnattaChat
