import { Paper, List, ListItem, ListItemText } from '@material-ui/core'
import React from 'react'
import LoadingSpinner from './loadingSpinner'
import DocumentLink from './documentLink'
import documentLocation from '../constants/documentLocation.constants'

const TopicDocumentList = ({ documents, sectionId, topicId, sectionAndTopicDetails }) => {
  if (!documents) {
    return <LoadingSpinner text={'Loading Policy Documents'} />
  }

  return (
    <>
      <Paper style={{ padding: 30 }}>
        <List>
          {documents.length ? (
            documents
              .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
              .map(document => (
                <ListItem key={document.id}>
                  <DocumentLink
                    documentId={document.id}
                    fileName={document.filename}
                    documentName={document.name}
                    showIcon={true}
                    sectionAndTopicDetails={sectionAndTopicDetails}
                    documentLocation={documentLocation.TOPIC}
                    
                  />
                </ListItem>
              ))
          ) : (
            <ListItem>
              <ListItemText primary='No documents found for this topic'></ListItemText>
            </ListItem>
          )}
        </List>
      </Paper>
    </>
  )
}

export default TopicDocumentList
