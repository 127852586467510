import { combineReducers } from 'redux'
import authReducer from './auth.reducer'
import notificationReducer from './notification.reducer'
import sectionReducer from './section.reducer'
import brandingReducer from './branding.reducer'
import appConfigReducer from './appconfig.reducer'
import userReducer from './user.reducer'
import serviceLabelReducer from './serviceLabel.reducer'
import searchReducer from './search.reducer'
import { serviceReducer } from './service.reducer'

const rootReducer = combineReducers({
  auth: authReducer,
  branding: brandingReducer,
  notifications: notificationReducer,
  section: sectionReducer,
  appConfig: appConfigReducer,
  user: userReducer,
  serviceLabel: serviceLabelReducer,
  search: searchReducer,
  service: serviceReducer,
})

export default rootReducer
