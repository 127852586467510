import React, { useState, useEffect, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Link,
  Button,
  IconButton,
  Menu,
  MenuItem,
  makeStyles,
  Badge,
  Box,
  InputBase,
  Container,
} from '@material-ui/core/'
import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import Divider from '@material-ui/core/Divider'
import LensIcon from '@material-ui/icons/Lens'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import history from '../history'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import { useParams } from 'react-router'
import { signOutRedirect } from '../services/auth.service'
import availabilityConstants from '../constants/availability.constants'
import ProtectedSection from '../components/protectedSection'
import roleConstants from '../constants/roles.constants'
import { showAccessLevelPrompt } from '../actions/appconfig.actions'
import TopicMenu from './topicMenu'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { serviceApplicationConstants } from '../constants/serviceApplications.constants'

import {
  setUserAvailabilityAsync,
  getUserAvailabilityAsync,
} from '../actions/user.actions'

const drawerWidth = 240

const useStyles = makeStyles(theme => {
  return {
    subNav: {
      backgroundColor: theme.palette.navigation.main,
      color: theme.palette.getContrastText(theme.palette.navigation.main),
      minHeight: '48px',
      display: 'flex',
      flexWrap: 'wrap',
    },
    desktopNav: {
      display: 'none',
      [theme.breakpoints.up('lg')]: {
        display: 'flex',
      },
    },
    navButton: {
      color: theme.palette.getContrastText(theme.palette.navigation.main),
    },
    mobileNav: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: 'flex',
      },
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      fontSize: '0.6rem',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    availabilityLens: {
      fontSize: '0.8em',
      marginRight: '8px',
    },
    availabilityBadgeAvailable: {
      backgroundColor: 'green',
    },
    availabilityBadgeBusy: {
      backgroundColor: 'yellow',
    },
    availabilityBadgeUnavailable: {
      backgroundColor: 'red',
    },
    menuItem: {
      paddingRight: 10,
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.common.white,
      margin: '10px',
    },
    searchIconContainer: {
      display: 'inline-block',
      verticalAlign: 'bottom',
      color: theme.palette.common.black,
      cursor: 'pointer',
      padding: 0,
      height: '36px',
      width: '36px',
      backgroundColor: theme.palette.primary.greenSuccess,
      borderBottomRightRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      textAlign: 'center',
    },
    searchInput: {
      padding: theme.spacing(1, 1, 1, 1),
      width: 200,
      color: theme.palette.common.black,
    },
  }
})

const SubHeader = ({ open, setOpen }) => {
  const classes = useStyles()

  const { sectionTopics } = useSelector(state => state.section)
  const { serviceLabelData } = useSelector(state => state.serviceLabel)
  const { isSearching } = useSelector(state => state.search)
  const serviceApplications = useSelector(state => state.branding.applications)

  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    if (!user.availability) {
      dispatch(getUserAvailabilityAsync())
    }
  }, []) // only run one

  const [accountIconAnchorEl, setAccountIconAnchorEl] = useState(null)

  const handleAvailabilityChange = availability => {
    dispatch(setUserAvailabilityAsync(availability))
    setAccountIconAnchorEl(null)
  }

  const dashboardUrl = serviceApplications.find(
    x => x.id === serviceApplicationConstants.DASHBOARD
  )?.url

  const handleCreateCase = () => {
    let queryParams = ''

    if (params.sectionId && params.topicId) {
      queryParams += `?sectionId=${params.sectionId}&topicId=${params.topicId}`
    }

    const returnUrl = window.location.href
    queryParams += queryParams ? '&' : '?'
    queryParams += `returnUrl=${returnUrl}`

    window.location.href = `${dashboardUrl}/cases/create${queryParams}`
  }

  const handleLogout = () => {
    handleAvailabilityChange(availabilityConstants.OFFLINE)
    setAccountIconAnchorEl(null)
    signOutRedirect()
  }

  const handleChangePolicy = () => {
    dispatch(showAccessLevelPrompt())
    setAccountIconAnchorEl(null)
  }

  const [searchTerm, setSearchTerm] = useState('')
  const handleSearch = () => {
    if (!isSearching) {
      const encodedSearchTerm = encodeURI(searchTerm)
      history.push(`/Search/${encodedSearchTerm}`)
    }
  }
  const handleSearchSubmit = e => {
    e.preventDefault()
    handleSearch()
  }

  const [topicMenuAnchorEl, setTopicMenuAnchorEl] = useState(null)
  const topicMenuOpen = event => {
    setTopicMenuAnchorEl(event.currentTarget)
  }
  const topicMenuClose = () => {
    setTopicMenuAnchorEl(null)
  }
  const topicMenuTopicSelected = (topic, selectedSection) => {
    topicMenuClose()
    history.push(selectedSection.isOtherSection ? `/other/${topic.id}` : `/topic/${topic.id}`)
  }

  return (
    <Fragment>
      {sectionTopics && (
        <div>
          <nav
            className={`${classes.subNav} ${classes.mobileNav} ${classes.navButton}`}>
            <IconButton
              className={classes.navButton}
              onClick={() => setOpen(true)}>
              <MenuIcon />
            </IconButton>
          </nav>
          <nav className={`${classes.subNav} ${classes.desktopNav}`}>
            <Container
              style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <Link
                  className={classes.navButton}
                  component={Button}
                  onClick={() => history.push('/')}>
                  Home
                </Link>

                <Button className={classes.navButton} onClick={topicMenuOpen}>
                  Policies <ArrowDropDownIcon />
                </Button>
                <Menu
                  anchorEl={topicMenuAnchorEl}
                  keepMounted
                  open={Boolean(topicMenuAnchorEl)}
                  onClose={topicMenuClose}
                  transitionDuration={0}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}>
                  <TopicMenu
                    topicSelected={topicMenuTopicSelected}
                    sectionTopics={sectionTopics}
                  />
                </Menu>
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  className={classes.navButton}
                  onClick={handleCreateCase}>
                  Create Case
                </Button>
                <form onSubmit={handleSearchSubmit}>
                  <Box className={classes.search}>
                    <InputBase
                      placeholder={'Search Knowledge Portal'}
                      className={classes.searchInput}
                      onChange={e => setSearchTerm(e.target.value)}
                      disabled={isSearching}
                    />
                    <div
                      role='button'
                      className={classes.searchIconContainer}
                      onClick={handleSearch}>
                      <SearchIcon />
                    </div>
                  </Box>
                </form>
                <Button
                  className={classes.navButton}
                  onClick={e => setAccountIconAnchorEl(e.currentTarget)}>
                  <Badge
                    classes={{
                      badge: classes[`availabilityBadge${user.availability}`],
                    }}
                    variant='dot'
                    overlap='circle'>
                    <AccountCircleIcon />
                  </Badge>
                </Button>
                <Menu
                  anchorEl={accountIconAnchorEl}
                  open={Boolean(accountIconAnchorEl)}
                  keepMounted
                  onClose={() => setAccountIconAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>
                  <ProtectedSection rolesRequired={[roleConstants.HR]}>
                    <MenuItem style={{ backgroundColor: 'transparent' }}>
                      Availability
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={() =>
                        handleAvailabilityChange(
                          availabilityConstants.AVAILABLE
                        )
                      }>
                      {' '}
                      <LensIcon
                        className={classes.availabilityLens}
                        style={{ color: 'green' }}
                      />{' '}
                      Available
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAvailabilityChange(availabilityConstants.BUSY)
                      }>
                      {' '}
                      <LensIcon
                        className={classes.availabilityLens}
                        style={{ color: 'yellow' }}
                      />{' '}
                      Busy
                    </MenuItem>
                    <MenuItem
                      onClick={() =>
                        handleAvailabilityChange(
                          availabilityConstants.UNAVAILABLE
                        )
                      }>
                      {' '}
                      <LensIcon
                        className={classes.availabilityLens}
                        style={{ color: 'red' }}
                      />{' '}
                      Unavailable
                    </MenuItem>
                    <Divider />
                  </ProtectedSection>
                  <MenuItem onClick={handleChangePolicy}>
                    <ImportExportIcon className={classes.menuItem} />
                    Change {serviceLabelData.accessLevelsHeading}
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>
                    <ExitToAppIcon className={classes.menuItem} />
                    Log Out
                  </MenuItem>
                </Menu>
              </div>
            </Container>
          </nav>
        </div>
      )}
    </Fragment>
  )
}

export default SubHeader
