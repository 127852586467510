import tokenConstants from '../constants/token.constants'
import { useSelector } from 'react-redux'

const ProtectedSection = ({ rolesRequired, children }) => {
  const userRoles = useSelector(
    state => state.auth.user.profile[tokenConstants.ROLES]
  )
  let hasRole = true

  if (rolesRequired) {
    if (typeof userRoles === 'string' && rolesRequired.length === 1) {
      // user roles is a string if only 1 role is present
      hasRole = userRoles === rolesRequired[0]
    } else if (Array.isArray(userRoles)) {
      // if roles is an array, check any required role is included in user roles
      hasRole = rolesRequired.some(role => userRoles.includes(role))
    }
  }

  return hasRole ? children : null
}

export default ProtectedSection
