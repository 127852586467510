import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import './index.css'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'

Sentry.init({
    dsn:
        'https://d580cca864724d0093e8170d1d421390@o511220.ingest.sentry.io/6299859',
    integrations: [new BrowserTracing()],
    environment: window.config.ENV,

    debug:
        window.config.SENTRY_DEBUG !== undefined
            ? window.config.SENTRY_DEBUG
            : true,

    sampleRate:
        window.config.SENTRY_SAMPLERATE !== undefined
            ? window.config.SENTRY_SAMPLERATE
            : 1.0,

    tracesSampleRate:
        window.config.SENTRY_TRACESSAMPLERATE !== undefined
            ? window.config.SENTRY_TRACESSAMPLERATE
            : 1.0,
})


ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)
