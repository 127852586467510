import React from 'react'
import ProtectedSection from '../components/protectedSection'
import { useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined'
import EqualizerOutlinedIcon from '@material-ui/icons/EqualizerOutlined'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import SettingsIcon from '@material-ui/icons/Settings'
import Box from '@material-ui/core/Box'

import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'

import roleConstants from '../constants/roles.constants'
import { serviceApplicationConstants } from '../constants/serviceApplications.constants'

const useStyles = makeStyles(theme => ({
  root: {
    height: 120,
    padding: '0 30px',
    backgroundColor: theme.palette.header.main,
    color: theme.palette.getContrastText(theme.palette.header.main),
  },
  mainLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 1rem',
    fontSize: '0.8rem',
    color: theme.palette.getContrastText(theme.palette.header.main),
    height: '2rem',
    '& span': {
      marginLeft: '0.4rem',
    },
    '&:hover': {
      textDecoration: 'none',
      borderBottom: '3px solid',
      color: theme.palette.getContrastText(theme.palette.header.main),
    },
  },
  logoText: {
    fontSize: '1.8rem',
  },

  logo: {
    maxWidth: 400,
    maxHeight: 120,
  },

  brandWelcomeText: {
    fontSize: '1rem',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
    backgroundColor: theme.palette.header.main,
  },
  sectionTablet: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
  },
}))

const Header = () => {
  const classes = useStyles()

  let { brandThemeConfig, applicationName } = useSelector(state => {
    return {
      brandThemeConfig: state.branding.theme || state.branding.defaultTheme,
      applicationName: state.service ? state.service.title : '',
    }
  })

  const serviceApplications = useSelector(state => state.branding.applications)

  return (
    <Box display='flex' className={classes.root} justifyContent='center'>
      <Box
        p={1}
        flexShrink={0}
        display='flex'
        alignItems='center'
        justifyContent='center'>
        <img
          src={brandThemeConfig.logoUrl}
          className={classes.logo}
          alt={'logo'}
        />
      </Box>
      <Box
        p={1}
        width='100%'
        display='flex'
        justifyContent='flex-end'
        alignItems='top'
        className={classes.sectionDesktop}>
        <ProtectedSection
          rolesRequired={[roleConstants.HR, roleConstants.BUSINESS]}>
          <Link
            href={
              serviceApplications.find(
                x => x.id === serviceApplicationConstants.DASHBOARD
              )?.url
            }
            className={classes.mainLink}>
            <FolderOutlinedIcon />
            <span>Case Manager</span>
          </Link>
        </ProtectedSection>

        <Link component={RouterLink} to='/' className={classes.mainLink}>
          <MenuBookIcon />
          <span>Knowledge Portal</span>
        </Link>
        <ProtectedSection rolesRequired={[roleConstants.MI]}>
          <Link href={window.config.MI_PORTAL_URL} className={classes.mainLink}>
            <EqualizerOutlinedIcon />
            <span>MI Portal</span>
          </Link>
        </ProtectedSection>
        <ProtectedSection rolesRequired={[roleConstants.ADMIN]}>
          <Link component={RouterLink} to='/' className={classes.mainLink}>
            <SettingsIcon />
            <span>Admin</span>
          </Link>
        </ProtectedSection>
      </Box>
      <Box
        p={1}
        flexShrink={0}
        flexGrow={1}
        display='flex'
        alignItems='top'
        justifyContent='flex-end'
        className={classes.sectionTablet}>
        <Typography className={classes.brandWelcomeText}>
          Welcome to <strong>{applicationName}</strong>
        </Typography>
      </Box>
    </Box>
  )
}

export default Header
