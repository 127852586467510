export const getUserRoles = profile => {
  if (!profile) {
    return []
  }

  const {
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': roles,
  } = profile

  if (Array.isArray(roles)) {
    return roles
  } else {
    return [roles]
  }
}
