export const createDocumentQueryString = (details) =>  {
    const queryObject = {
        sectionId: details.sectionId,
        sectionName: details.sectionName,
        topicId: details.topicId,
        topicName: details.topicName,
        documentId: details.documentId,
        fileName : details.fileName,
        documentName: details.documentName,
        documentLocation: details.documentLocation,
      }
      return Object.keys(queryObject)
        .map(key => key + '=' + queryObject[key])
        .join('&')
    }

