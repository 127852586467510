import { SET_IS_SEARCHING } from '../actions/types'

export const setIsSearching = isSearching => {
  return async dispatch => {
    return dispatch({
      type: SET_IS_SEARCHING,
      payload: isSearching,
    })
  }
}
