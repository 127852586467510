import httpClient from '../utils/axios'
import {
  handleServiceError,
  handleServiceResponse,
} from '../utils/serviceResponseHelper'

async function setUserAvailabilityAsync(availability) {
  return httpClient
    .put(`${window.config.USER_API_URL}/api/user/availability/${availability}`)
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

async function getUserAvailabilityAsync() {
  return httpClient
    .get(`${window.config.USER_API_URL}/api/user/availability`)
    .then(response => {
      return handleServiceResponse(response)
    })
    .catch(error => handleServiceError(error))
}

export default {
  setUserAvailabilityAsync,
  getUserAvailabilityAsync,
}
