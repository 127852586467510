import httpClient from '../utils/axios'
import {
  handleServiceResponse,
  handleServiceError,
} from '../utils/serviceResponseHelper'

function getHrAccessLevels() {
  return httpClient
    .get(
      `${window.config.IDENTITY_URL}/account/updateaccesslevel?clientId=${
        window.config.OIDC_CLIENT_ID
      }&accessLevelId=${1}&redirect_uri=${window.location.protocol}//${
        window.location.hostname
      }${window.location.port ? `:${window.location.port}` : ''}`,
      { withCredentials: true }
    )
    .then(
      response => handleServiceResponse(response),
      error => handleServiceError(error)
    )
}

export function getVisibleAccessLevels() {
  return httpClient.get(`${window.config.KNOWLEDGEPORTAL_API_URL}/api/AccessLevels/visibleaccesslevels`)
}

export default {
  getHrAccessLevels,
}
