import React from 'react'
import { useParams } from 'react-router-dom'
import OtherSectionContent from '../components/otherSectionContent'
import MainLayout from '../layouts/mainLayout'

const OtherSection = () => {
  const { otherSectionId } = useParams()

  return (
    <>
      <MainLayout>
        <OtherSectionContent otherSectionId={otherSectionId} />
      </MainLayout>
    </>
  )
}

export default OtherSection
